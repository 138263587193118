import { Link } from "gatsby"
import React from "react"

import styles from "./landing.module.scss"

import logoIcon from "../images/logo.svg"

const Landing = () => (
  <section className={styles.landing} >
      <img className={ styles.landing__logo } src={ logoIcon } alt="EMFiGo logo"/>

      <h1 className={ styles.landing__slogan }><strong>Committed</strong> to solving problems, <strong>passionate</strong> for helping people &hellip;</h1>

      <div className={ styles.contentStart }>
          <Link to="/#header">▾</Link>
      </div>
  </section>
)

export default Landing
