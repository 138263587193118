import { Link } from "gatsby"
import React from "react"

import styles from "./footer.module.scss"

import emailIcon from "../images/email.svg"
import linkedinIcon from "../images/linkedin.svg"

const Footer = () => (
  <footer className={[styles.footer, "u-wrap"].join(" ")} >
        <a className={styles.footer__logo} href="https://www.linkedin.com/in/emfigo">
           <img src={ linkedinIcon } alt=""/>
        </a>
        <Link className={styles.footer__logo} to="/contactme/">
           <img src={ emailIcon } alt=""/>
        </Link>
  </footer>
)

export default Footer
