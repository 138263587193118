import React from "react"

import Layout from "../components/layout"
import rubyIcon from "../images/languages/ruby.svg"

const BackgroundPage = () => (
  <Layout>
    <section className="content__body--portfolio">
      <div className="intro">
          <h1>Open source projects</h1>
          <p>
              I tend to dedicate more time to private than open sourced projects, but in the last years I've been trying to
              collaborate more with the comunity. These are a few of the projects that I created in a certain point for an
              specific purpose, but still there is a lot to improve, so please take a look and feel free to collaborate
              if you wish:
          </p>
      </div>
      <a href="https://github.com/emfigo/absplit">
          <img src={ rubyIcon } alt="Ruby logo"/>
          <span>
              For statistical hypothesis testing based on weights given by the user
          </span>
      </a>
      <a href="https://github.com/emfigo/grada">
          <img src={ rubyIcon } alt="Ruby logo"/>
          <span>
              Plotting gem. I have to admit that was my first works and probably the one with more errors
          </span>
      </a>
      <a href="https://github.com/emfigo/cluda">
          <img src={ rubyIcon } alt="Ruby logo"/>
          <span>
              For grouping data points into clusters
          </span>
      </a>
    </section>
  </Layout>
)

export default BackgroundPage
