import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "../styles/index.scss"

import Footer from "./footer"
import Header from "./header"
import Landing from "./landing"

const Layout = ({ children, landing, backgroundTab, whoamiTab }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        { landing && <Landing /> }
        <div className="main">
          <Header
            siteTitle={data.site.siteMetadata.title}
            backgroundTab={ backgroundTab }
            whoamiTab={ whoamiTab }
          />
          <section className={["content", "u-wrap"].join(" ")}>
             { children }
          </section>
          <Footer/>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  landing: PropTypes.bool,
  backgroundTab: PropTypes.string,
  whoamiTab: PropTypes.string,
}

Layout.defaultProps = {
  landing: false,
  backgroundTab: ``,
  whoamiTab: ``,
}

export default Layout
