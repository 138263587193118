import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./header.module.scss"

import happyFace from "../images/happyface.svg"

const Header = ({ siteTitle, backgroundTab, whoamiTab }) => (
  <header id="header" className={[styles.header, "u-wrap"].join(" ")} >
        <Link className={styles.header__logo} to="/#header">
           <img src={ happyFace } alt="EMFiGo happyface"/>
        </Link>
        <div>
           <Link className={styles["header__tab" + backgroundTab]} to="/background/">background</Link>
           <Link className={styles["header__tab" + whoamiTab]} to="/whoami/">whoami</Link>
        </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  backgroundTab: PropTypes.string,
  whoamiTab: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  backgroundTab: ``,
  whoamiTab: ``,
}

export default Header
